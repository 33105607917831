const greenCareOrganic = {
  name: "Green Care Organic",
  location: "Puttalam",
  images: 8,
  slug: "puttalam",
  certifications: "PGS Certified",
  description: `This farm focuses on organic food and farming to improve the health of humans and the environment. Their main products are gotukola and organic fruits and vegetables. They reinvest a portion of all profits to conduct organic workshops in rural areas and support organic cultivation development.
This organic farm in Galkulama, Puttalam is verified under a local participatory guarantee system (PGS). They also operate a supermarket for organic and natural products and support other PGS verified farmers and natural food producers. `,
}
module.exports = greenCareOrganic
