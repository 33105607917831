import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../Layout"
import Seo from "../components/seo"
import SlideShow from "../components/HomePage/SldieShow"
import FarmBlock from "../components/FarmBlock"
import HomeProductsBlock from "../components/HomePage/HomeProductsBlock"
import farms from "../data/farms"
import * as styles from "./index.module.scss"
import PGS from "../images/certifications/1.png"
import ControlUnion from "../images/certifications/2.jpg"
import SriCert from "../images/certifications/3.jpg"
// import get from "lodash/get"
// import * as s from "./blog/index.module.scss"
import { format, parseISO } from "date-fns"
import Welcome from "../components/HomePage/Welcome"
import isOnlineStoreOpen from "../config/isOnlineStoreOpen"

const cap = typeof window !== "undefined" && window.innerWidth < 768 ? 4 : 6

const HomePage = ({ data }) => {
  // const blogs = get(data, "allPrismicBlogPost.nodes")
  return (
    <Layout>
      <Seo title="Welcome" />
      <SlideShow />

      {isOnlineStoreOpen && <HomeProductsBlock />}

      <Welcome />

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 col-xl-4 offset-xl-4 text-center mt-5">
            <h2>Our Partner Farms</h2>
            <p>
              Owita organic is supported by 25+ dedicated farms which grows
              fully organic products.
            </p>
          </div>
        </div>

        <div className="row">
          {farms.slice(0, cap).map((farm, idx) => {
            return <FarmBlock farm={farm} key={idx} noBorder={idx === 0} />
          })}
        </div>

        <div className="row">
          <div className="col-sm-12 text-center my-5">
            <Link to={"/our-partner-farms"} className={"btn btn-primary"}>
              View All Farms
            </Link>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-6">
            <h2 className={"text-center mt-5"}>
              Our food should be our medicine!
            </h2>
            <p className="text-center">
              Owita is the complete Organic and healthy food solution, which
              allows Sri Lankans to grow and eat Organic, Fresh and healthy
              food.
            </p>
          </div>
        </div>

        <div className={styles.certifications}>
          <img src={PGS} alt="PGS Certification" width={150} />
          <img
            src={ControlUnion}
            alt="Control Union Certification"
            width={250}
          />
          <img src={SriCert} alt="Sri Cert Certification" width={150} />
        </div>

        {/*<BlogSection blogs={blogs} />*/}
      </div>
    </Layout>
  )
}
export default HomePage

const BlogSection = ({ blogs }) => {
  return (
    <div>
      <div className="container mt-5 mb-3 pt-5">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8 col-xl-6 text-center mt-3 mb-5">
            <div>
              <h2>Blog Posts</h2>
              <p>Be informed. Join and read our education blog posts.</p>
            </div>
          </div>
        </div>

        <div className="row">
          {blogs.slice(0, 2).map(
            ({
              id,
              uid,
              last_publication_date,
              data: {
                excerpt,
                post_title: { text: title },
                image,
                ...others
              },
            }) => {
              return (
                <div className={"col-md-6 mb-5"} key={id}>
                  <h5>
                    <strong>{title}</strong>
                  </h5>
                  <time className={"text-muted"}>
                    {format(parseISO(last_publication_date), "MMMM do, yyyy")}
                  </time>
                  <div>
                    <div>
                      <img
                        src={image.thumbnails.thumbnail.url}
                        alt={title}
                        className={"w-100 my-3"}
                      />
                    </div>
                    <div>
                      <p>{excerpt}</p>
                      <Link className={"btn btn-link px-0"} to={`/blog/${uid}`}>
                        Read article
                      </Link>
                    </div>
                  </div>
                </div>
              )
            }
          )}

          <div className="col-12 text-center">
            <Link to={"/blog"} className={"btn btn-primary"}>
              See all blog posts
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    allPrismicBlogPost {
      nodes {
        data {
          excerpt
          post_title {
            text
          }
          image {
            thumbnails {
              thumbnail {
                url
              }
            }
          }
        }
        id
        uid
        last_publication_date
      }
    }
  }
`
