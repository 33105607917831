import React from "react"
import * as s from "./index.module.scss"
import { Link } from "gatsby"
import Logo from "../../../images/favicon.png"
import { BsPeopleFill } from "react-icons/bs"
import { IoMdCart } from "react-icons/io"

const Welcome = () => {
  return (
    <div className={"container  my-5"}>
      <div className="row justify-content-center">
        <div className="col-sm-6 text-center">
          <img src={Logo} alt="Welcome to Owita Organics" width={128} />
          <h1 className={s.title}>
            <small>Welcome to</small>
            Owita Organics
          </h1>
          <p>
            Owita Organics opened its doors in 2017. With over 50 acres of land
            cultivated with organic vegetables and fruits, our goal is to make
            Organic &amp; healthy food more accessible to all human beings.
          </p>
          <a className={"btn btn-primary m-2"} href={"https://store.owita.lk"}>
            Go to Shop <IoMdCart />
          </a>
          <Link className={"btn btn-primary m-2"} to={"/about-us"}>
            About Owita Organics <BsPeopleFill />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Welcome
